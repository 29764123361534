import { DynamicRouteData } from '@modules/navigation/models';

import { sharedMetaTags } from './_shared-meta-tags';

export const demo: DynamicRouteData = {
    isPublic: true,
    title: 'Alpha147 Demos',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const demoHealthcareChat: DynamicRouteData = {
    isPublic: true,
    title: 'Alpha147 Demos',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const demoConfigs = {
    demo,
    demoHealthcareChat,
};
