import type { BuildInfo } from '@common/models/build-info.model';

export const buildInfo: BuildInfo = {
    commitSHA: '546b8bc3c671f5cefc3f875f66f2a69b8e5da4e7',
    branch: 'main',
    deployEnv: 'prod',
    version: '2.0.2',
    dateFormatted: '2024-12-19--15-27',
    dateISOString: '2024-12-19T15:27:45.562Z',
};
